import React, { useEffect, useState, useContext } from "react";
import Hero from "../Hero/Hero";
import WhatsAppButton from "../../Common/whatsapp/WhatsappButton";
import "./Services/service.css";
import "./salonprofilepage.css";
import Skeleton from "react-loading-skeleton";
import { useParams, Link } from "react-router-dom";
import AuthContext from "../../../context/Auth";
import Grids from "../../../Assets/images/icons/white_grid.svg";
import Phone from "../../../Assets/images/icons/phone.svg";
import Man from "../../../Assets/images/icons/man.svg";
import Popup from "../../Common/Popup/Popup";
import Gallery from "../../Common/Gallery/Gallery";
import Gallery2 from "../../Common/Gallery2/Gallary.jsx";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Reviews from "../../Common/Reviews/Reviews";
import Footer from "../../Common/Footer/Footer";
import ProfileReview from "../../Common/Reviews/ProfileReview";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { format } from "timeago.js";

import { FcLike, FcShare } from "react-icons/fc";
import { MdFavoriteBorder } from "react-icons/md";

import SigninForms from "../../Common/Header/signupsigninforms/SigninForms";
import Services from "./Services/Service";
import ClientWorkPhoto from "./ClientWorkImages/ClientWorkImages";
import SalonDailyUpdates from "./DailyUpdates/DailyUpdates";
import AboutUsSalon from "./AboutUs/AboutUs";

import Score_svg from "../../../Assets/images/icons/score_svg.svg";
import Info_svg from "../../../Assets/images/icons/info_i_svg.svg";
import share_svg from "../../../Assets/images/icons/share_svg.svg";
import share_w_svg from "../../../Assets/images/icons/share_w-svg.svg";
import like_svg from "../../../Assets/images/icons/like_svg.svg";

import MemberShip from "./membership/MemberShip";
import FooterN from "../../Common/Footer/FooterN";
import CustomerExp from "./ModalComponent/CustomerExp";
import MemberShipModal from "./ModalComponent/MemberShipModal";
import AboutUsModal from "./ModalComponent/AboutUsModal";
import UserScoreModal from "./ModalComponent/UserScoreModal";
import GrommingPackages from "./GroomingPackages/GrommingPackages";
import OfferSalon from "./OfferSalon/OfferSalon.jsx";
import { Avatar } from "@mui/material";
import Signup from "../../Common/Header/SignUp2/Signup.jsx";
import toast, { Toaster } from "react-hot-toast";
import loading_gif from "../../../Assets/images/logos/Trakky website graphics.gif";
import ProfilepageHeader from "../../Common/Navbar/ProfilepageHeader.jsx";
import SalonTimingModal from "./ModalComponent/SalonTimingModal.jsx";

import { useSearchParams } from "react-router-dom";

import {
  Close,
  InfoOutlined,
  InfoRounded,
  InfoSharp,
} from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { capitalizeAndFormat } from "../../functions/generalFun.js";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const SalonProfile = () => {
  const {
    user,
    authTokens,
    location,
    userFavorites,
    fetchUserFavorites,
    userData,
    fetchUserData,
  } = useContext(AuthContext);

  const params = useParams();
  const { slug } = params;

  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = window.matchMedia("(max-width: 600px)").matches;

  const swiperRef = React.useRef(null);

  const [isLoading, setIsLoading] = useState(true);

  const [mainImageSkeleton, setMainImageSkeleton] = useState(true);
  const [salon, setSalon] = useState(null);

  // service
  const [allServices, setAllServices] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [activeServiceGender, setActiveServiceGender] = useState("male");
  const [categoryListOfGender, setCategoryListOfGender] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [genderFilteredServices, setGenderFilteredServices] = useState([]);
  const [activeServiceData, setActiveServiceData] = useState([]);
  const [desktopVisibleScore, setDesktopVisibleScore] = useState(5);

  const [userNameOfUser, setUserNameOfUser] = useState("");
  const [nameUpdateLoading, setNameUpdateLoading] = useState(false);

  const [suggestedSalonData, setSuggestedSalonData] = useState([]);

  const [scoreData, setScoreData] = useState([]);
  const [activeScore, setActiveScore] = useState(5);

  const [salonOfferData, setSalonOfferData] = useState([]);

  const [timings, setTimings] = useState("");

  //  All modal Trigger

  const [salonProfilePhotosTrigger, setSalonProfilePhotosTrigger] =
    useState(false);
  const handleSalonProfilePhotosClose = () => {
    window.history.back();
    setSalonProfilePhotosTrigger(false);
  };
  const handleSalonProfilePhotosOpen = () => {
    window.history.pushState(null, null, window.location.href);
    setSalonProfilePhotosTrigger(true);
  };

  // Signin Modal
  const [openSignIn, setOpenSignIn] = useState(false);
  const handleSignInOpen = () => setOpenSignIn(true);
  const handleSignInClose = () => setOpenSignIn(false);

  // aboutUS modal
  const [openAboutUs, setOpenAboutUs] = useState(false);
  // const handleAboutUsOpen = () => setOpenAboutUs(true);
  // const handleAboutUsClose = () => setOpenAboutUs(false);

  const handleAboutUsOpen = () => {
    window.history.pushState(null, null, window.location.href);
    setOpenAboutUs(true);
  };

  const handleAboutUsClose = () => {
    window.history.back();
    setOpenAboutUs(false);
  };

  const [openSalonTiming, setOpenSalonTiming] = useState(false);

  const [userNameChangeModal, setUserNameChangeModal] = useState(false);

  const [productData, setProductData] = useState([]);

  const [dailyUpdatesOpen, setDailyUpdatesOpen] = useState(false);
  const handleDailyUpdatesOpen = () => {
    window.history.pushState(null, null, window.location.href);
    setDailyUpdatesOpen(true);
  };

  const handleDailyUpdatesClose = () => {
    window.history.back();
    setDailyUpdatesOpen(false);
  };

  const [userScoreModal, setUserScoreModal] = useState(false);
  const handleUserScoreOpen = () => {
    window.history.pushState(null, null, window.location.href);
    setUserScoreModal(true);
  };
  const handleUserScoreClose = () => {
    window.history.back();
    setUserScoreModal(false);
  };

  const [clientExpOpen, setClientExpOpen] = useState(false);
  const handleClientExpOpen = () => {
    window.history.pushState(null, null, window.location.href);
    setClientExpOpen(true);
  };

  const handleClientExpClose = () => {
    window.history.back();
    setClientExpOpen(false);
  };

  const [offerrModalOpen, setOfferModalOpen] = useState(false);
  const [offerModalData, setOfferModalData] = useState({});
  const handleOfferOpen = (data) => {
    window.history.pushState(null, null, window.location.href);
    setOfferModalData(data);
    setOfferModalOpen(true);
  };
  const handleModalClose = () => {
    window.history.back();
    setOfferModalOpen(false);
    setOfferModalData({});
  };

  window.onpopstate = function (event) {
    console.log("general Popstate");

    if (dailyUpdatesOpen) {
      console.log("closing daily updates");
      setDailyUpdatesOpen(false);
    }
    if (clientExpOpen) {
      console.log("closing client exp");
      setClientExpOpen(false);
    }
    if (salonProfilePhotosTrigger) {
      console.log("closing salon profile photos");
      setSalonProfilePhotosTrigger(false);
    }
    if (userScoreModal) {
      console.log("closing user score modal");
      setUserScoreModal(false);
    }
    if (openAboutUs) {
      console.log("closing about us modal");
      setOpenAboutUs(false);
    }

    if (offerrModalOpen) {
      console.log("closing offer modal");
      setOfferModalOpen(false);
    }
  };

  // all functions

  const getScoreBySalon = async (salonId) => {
    let url = `https://trakky.in:8000/salons/score/?salon=${salonId}`;

    try {
      let res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      let data = await res.json();

      setScoreData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserScore = async (e) => {
    let url = `https://trakky.in:8000/salons/score/`;

    if (!user?.user_id) {
      setOpenSignIn(true);
      return;
    }

    if (user?.user_id && userData) {
      if (!userData?.name) {
        setUserNameChangeModal(true);
        return;
      }
    }

    if (user?.user_id && activeScore) {
      handleSubmitScore();
    }
  };

  const handleSubmitScore = async (e) => {
    let url = `https://trakky.in:8000/salons/score/`;

    let Scoredata = {
      salon: salon?.id,
      score: activeScore,
    };

    try {
      let res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify(Scoredata),
      });

      let data = await res.json();
      getScoreBySalon(salon?.id);
      if (res.status === 201) {
        toast.success("Score added successfully.", {
          duration: 2000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setMiddleScore = () => {
    const swiper = swiperRef.current?.swiper;
    setActiveScore(swiper?.activeIndex + 1);
    swiperRef.current?.swiper.slideTo(swiper.activeIndex, 0);
  };

  const calculateScore = (scoreData) => {
    let totalScore = 0;
    scoreData?.map((item) => {
      totalScore += item.score;
    });

    if (scoreData.length === 0) {
      return 0;
    }

    let finalVal = (totalScore / scoreData.length).toFixed(1);

    if (finalVal === "NaN") {
      return 0;
    }
    // 10.0 -> 10
    if (finalVal % 1 === 0) {
      return parseInt(finalVal);
    }

    return finalVal;
  };

  const handleShare = () => {
    if (!navigator.share) {
      document.execCommand("copy", true, window.location.href);
      alert("Link copied to clipboard");
      return;
    }

    navigator
      .share({
        title: salon?.name || "Trakky",
        text: "Check out this salon",
        url: window.location.href,
      })
      .catch((error) => console.log("Error sharing", error));
  };

  const signInFormStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const getSalonBySlug = async (slug) => {
    try {
      const response = await fetch(
        `https://trakky.in:8000/salons/?slug=${slug}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      setSalon(data?.results[0]);
      setIsLoading(false);
      setAllServices(data?.results[0]?.services);
      setAllCategories(data?.results[0]?.category);
    } catch (error) {
      console.log(error);
    }
  };

  const formateAddress = (address) => {
    const lastCommaIndex = address.lastIndexOf(",");
    const secondLastCommaIndex = address.lastIndexOf(",", lastCommaIndex - 1);

    const areaAndCity = address.substring(secondLastCommaIndex + 2);

    return [address.substring(0, secondLastCommaIndex), areaAndCity];
  };

  const getSalonTimings = async () => {
    let weeklytiming = salon?.salon_timings;

    if (!weeklytiming) {
      return;
    }

    let today = await moment().format("dddd").toLowerCase();

    console.log(today);

    if (weeklytiming[today] === "closed") {
      console.log("Closed today");
      return "Closed today";
    }

    let openTime = weeklytiming[today]?.open_time;
    let closeTime = weeklytiming[today]?.close_time;

    console.log(openTime, closeTime);

    // format time
    let formatedOpenTime = formateTime(openTime);
    let formatedCloseTime = formateTime(closeTime);

    console.log(formatedOpenTime, formatedCloseTime);

    return `Opens ${formatedOpenTime} - ${formatedCloseTime}`;
  };
  const formateTime = (time) => {
    if (!time) {
      return;
    }

    let [hour, minute, second] = time.split(":");

    let formatedTime = "";

    if (parseInt(hour) > 12) {
      formatedTime = `${parseInt(hour) - 12}:${minute} PM`;
    } else {
      formatedTime = `${parseInt(hour)}:${minute} AM`;
    }
    if (parseInt(hour) === 12) {
      formatedTime = `${hour}:${minute} PM`;
    }
    if (parseInt(hour) === 0) {
      formatedTime = `12:${minute} AM`;
    }
    if (parseInt(hour) === 24) {
      formatedTime = `12:${minute} AM`;
    }
    if (parseInt(minute) === 0) {
      formatedTime =
        parseInt(hour) > 12
          ? `${parseInt(hour) - 12} PM`
          : parseInt(hour) === 0
          ? `12 AM`
          : `${parseInt(hour)} AM`;
    }

    return formatedTime;
  };

  const handleBookNowBtn = () => {
    if (!salon?.name) {
      return;
    }
    let link = `https://api.whatsapp.com/send?phone=916355167304&text=Can%20I%20know%20more%20about%20Offers%20%26%20salon%20services%20of%20${encodeURIComponent(
      salon?.name
    )}%2C%20${encodeURIComponent(salon?.area)}%2C%20${encodeURIComponent(
      salon?.city
    )}%3F`;

    window.open(link, "_blank");
  };

  const log_adder = async (name, type) => {
    const requestBody = {
      category: "salon",
      name: name,
      location,
      actiontype: type,
    };

    if (user != null) {
      requestBody.salon_user = user?.user_id || null;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        "https://trakky.in:8000/salons/log-entry/",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to log entry");
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error logging entry:", error.message);
    }
  };

  const handlelike = async (id) => {
    if (!authTokens || !id) {
      return;
    }

    const response = await fetch(
      "https://trakky.in:8000/salons/userfavorite/",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify({
          salon: id,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        fetchUserFavorites();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handledislike = async (id) => {
    let likeId = userFavorites?.find((item) => item.salon === id)?.id;

    if (!likeId) {
      return;
    }

    const response = await fetch(
      `https://trakky.in:8000/salons/userfavorite/${likeId}/`,
      {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    fetchUserFavorites();
  };

  const getSuggestedSalon = async (city, area) => {
    let url = `https://trakky.in:8000/salons/suggested-salon/?city=${encodeURIComponent(
      city
    )}&area=${encodeURIComponent(area)}&verified=true`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      let data1 = await data?.filter((item) => item.slug !== params?.slug);

      setSuggestedSalonData(data1);
    } catch (error) {
      console.log(error);
    }
  };

  const getOfferFormat = (custom_offer_tag, offer_tag) => {
    let offerFormat = ""; // Variable to store the offer format
    let offerContent = ""; // Variable to store the content after the first word

    if (custom_offer_tag === null || custom_offer_tag === undefined) {
      offerContent = offer_tag;
      return { offerFormat, offerContent };
    }
    // Splitting the offer tag by space
    const offerParts = custom_offer_tag?.split(" ");
    if (offerParts[0] === "Get") {
      if (offerParts[1].endsWith("%")) {
        offerFormat = "percentage"; // Format: Get ${any_%} % off on ${servicename}
        offerContent = custom_offer_tag;
      } else if (offerParts[offerParts.length - 1] === "off") {
        offerFormat = "fixed-amount"; // Format: Get ₹${any_amount} off
        offerContent = custom_offer_tag;
      } else {
        offerFormat = "fixed-amount-service"; // Format: Get ₹${any_amount} off on ${servicename}
        offerContent = custom_offer_tag;
      }
    } else {
      offerFormat = "service-price"; // Format: ${servicename} at ₹${any_amount}
      offerContent = custom_offer_tag;
    }
    return { offerFormat, offerContent };
  };

  const getOfferOfSalonProfile = async (salonId) => {
    let url = `https://trakky.in:8000/salons/salon-profile-offer/?salon_id=${salonId}`;

    try {
      let res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      let data = await res.json();

      setSalonOfferData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitUserName = async (e) => {
    e.preventDefault();

    setNameUpdateLoading(true);

    if (!userNameOfUser) {
      toast.error("Please enter your name.");
      setNameUpdateLoading(false);
      return;
    }

    if (!user) {
      toast.error("User not found. Please login.");
      setNameUpdateLoading(false);
      return;
    }

    let url = `https://trakky.in:8000/salons/salonuser/${user?.user_id}/`;

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access_token}`,
      },
      body: JSON.stringify({
        name: userNameOfUser,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUserNameOfUser("");
        setUserNameChangeModal(false);
        toast.success("Name updated successfully.", {
          duration: 2000,
        });
        handleSubmitScore();
        setNameUpdateLoading(false);
        fetchUserData();
      })
      .catch((error) => {
        toast.error("Failed to update Name. Please try again.");
        setNameUpdateLoading(false);
      });
  };

  const getProductOfSalon = async (salon_slug) => {
    let url = `https://trakky.in:8000/salons/productofsalon/?salon_slug=${salon_slug}`;

    try {
      let res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        let data = await res.json();

        setProductData(data?.results);
      } else {
        console.log("Error fetching master products:", res.status);

        setProductData([]);
      }
    } catch (error) {
      console.error("Error fetching master products:", error.message);
    }
  };

  useEffect(() => {
    if (allServices?.length == 0) {
      return;
    }
    let filterservice = allServices?.filter((service) => {
      return service?.gender?.toLowerCase() === "male";
    });

    if (filterservice?.length == 0) {
      setActiveServiceGender("female");
    }
  }, [allServices]);

  useEffect(() => {
    swiperRef.current?.swiper.slideTo(activeScore - 1, 0);
  }, [activeScore, swiperRef.current?.swiper]);

  // client experience

  useEffect(() => {
    if (slug) {
      getSalonBySlug(slug);
      getProductOfSalon(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (salon) {
      getScoreBySalon(salon?.id);
      getOfferOfSalonProfile(salon?.id);
    }
  }, [salon]);

  useEffect(() => {
    if (params?.city && params?.area) {
      getSuggestedSalon(params?.city, params?.area);
    }
  }, [params?.city, params?.area]);

  // service
  useEffect(() => {
    if (allCategories) {
      let filteredCategories = allCategories.filter((category) => {
        return (
          category?.category_gender?.toLowerCase() ===
          activeServiceGender.toLowerCase()
        );
      });

      filteredCategories.unshift({ id: "all", name: "All Services" });
      setActiveCategory(filteredCategories[0]?.id);
      setCategoryListOfGender(filteredCategories);
    }
  }, [allCategories, activeServiceGender]);

  useEffect(() => {
    if (allServices) {
      let filteredServices = allServices.filter((service) => {
        return (
          service?.gender?.toLowerCase() === activeServiceGender.toLowerCase()
        );
      });

      setGenderFilteredServices(filteredServices);
    }
  }, [allServices, activeServiceGender]);

  useEffect(() => {
    getSalonTimings().then((data) => {
      setTimings(data);
    });
  }, [salon]);

  useEffect(() => {
    if (activeCategory === "all") {
      setActiveServiceData(genderFilteredServices);
    } else {
      let filteredServices = genderFilteredServices.filter((service) => {
        return service?.categories === activeCategory;
      });
      setActiveServiceData(filteredServices);
    }
  }, [activeCategory, genderFilteredServices]);

  return isLoading ? (
    <>
      <div className="w-full h-[100vh] flex justify-center items-center">
        <img src={loading_gif} className="h-[200px] w-[200px]" alt="" />
      </div>
    </>
  ) : (
    <div className="w-full">
      <Helmet>
        <title>
          {salon?.name}, {capitalizeAndFormat(params?.area)},{" "}
          {capitalizeAndFormat(params?.city)} | Trakky
        </title>
        <meta
          name="description"
          content={`${salon?.name}, ${capitalizeAndFormat(
            params?.area
          )}, ${capitalizeAndFormat(params?.city)}, view reviews, ${
            salon?.name
          } offers,  menu of ${salon?.name}, contact details of ${
            salon?.name
          }, location of ${salon?.name},`}
        />
      </Helmet>
      <Toaster />
      {/* <Hero isSalonP={true} /> */}
      <ProfilepageHeader handleOpenLogin={handleSignInOpen} />

      {window.innerWidth > 768 ? (
        <>
          <div className="NW-salon-main-container">
            <div className="NW-salon-main-data">
              <div className="NW-image-main-container">
                <div className=" w-[100%]   h-[270px]  max-h-[270px] lg:h-[370px] lg:max-h-[370px] gap-3  grid grid-cols-2  ">
                  <div className=" w-[100%] h-[370px] lg:h-[370px] ">
                    <img
                      src={salon?.main_image}
                      onLoad={() => {
                        setMainImageSkeleton(false);
                      }}
                      className={`${
                        mainImageSkeleton ? "hidden" : "block"
                      } object-fit  w-[100%]  h-[100%] `}
                      alt=""
                    />

                    {mainImageSkeleton && (
                      <Skeleton
                        variant="rectangle"
                        className=" w-[100%] h-[100%] "
                      />
                    )}
                  </div>
                  <div className="   grid grid-cols-2  grid-rows-2 h-[270px] lg:h-[370px] gap-3 min-h-[270px]  max-h-[270px] lg:min-h-[370px]  lg:max-h-[370px] ">
                    {salon?.mul_images ? (
                      salon.mul_images.slice(0, 4).map((url, index) => {
                        return (
                          <div className="w-[100%] h-[100%]  " key={index}>
                            <img
                              src={url?.image}
                              className={` ${
                                mainImageSkeleton ? "hidden" : "block"
                              } h-[100%] w-[100%] object-fit`}
                              alt=""
                            />
                            {mainImageSkeleton && (
                              <Skeleton
                                variant="rectangle"
                                className=" w-[100%] h-[100%] "
                              />
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <Skeleton
                          className=" w-[100%] h-[100%] "
                          variant="rectangle"
                        />
                        <Skeleton
                          className=" w-[100%] h-[100%] "
                          variant="rectangle"
                        />
                        <Skeleton
                          className=" w-[100%] h-[100%] "
                          variant="rectangle"
                        />
                        <Skeleton
                          className=" w-[100%] h-[100%] "
                          variant="rectangle"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="NW-salon-information__container relative">
                  <div className="NW-show-all-photos">
                    <button onClick={handleSalonProfilePhotosOpen}>
                      <img
                        src={Grids}
                        style={{
                          height: "unset",
                          width: "unset",
                        }}
                        alt=""
                      />
                      Show all photos
                    </button>
                  </div>
                </div>
                {userFavorites?.some((item) => {
                  let res = item?.salon === salon?.id;
                  return res;
                }) ? (
                  <div
                    className="NW-salon-liked-btn"
                    onClick={() => {
                      user ? handledislike(salon?.id) : handleSignInOpen();
                    }}
                  >
                    <FcLike />
                  </div>
                ) : (
                  <div
                    className="NW-salon-liked-btn"
                    onClick={() => {
                      user ? handlelike(salon?.id) : handleSignInOpen();
                    }}
                  >
                    <MdFavoriteBorder />
                  </div>
                )}

                <div className="NW-salon-share-btn" onClick={handleShare}>
                  <img src={share_w_svg} alt="" />
                </div>
              </div>
              <div className=" text-sm leading-4 h-6 flex items-end font-light cursor-pointer">
                <span
                  onClick={() => {
                    setOpenSalonTiming(true);
                  }}
                >
                  {timings}
                </span>
              </div>
              <div className="NW-salon-name-address-container">
                <div className="NW-salon-name-container">
                  <h1 className="NW-name">{salon && salon.name}</h1>
                  <div className="NW-address">
                    {salon?.area}, {salon?.city}
                  </div>
                  <div className="NW-Price-onward-tag">
                    <span> &#8377; {salon && salon?.price} Onwards</span>
                  </div>
                </div>
                <div className="NW-salon-offer-tag-container">
                  <div className="NW-salon-special-tag">
                    {salon?.premium && <span>Premium</span>}
                    {salon?.bridal && <span>Bridal</span>}
                    {salon?.salon_academy && <span>Academy Salon</span>}
                  </div>
                  <div className="NW-salon-Dekstop-score">
                    <img src={Score_svg} alt="" />
                    <span>
                      Score
                      {salon?.avg_score
                        ? String(salon?.avg_score).slice(0, 3)
                        : 0}
                      /10
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="NW-main-facilities-container">
              <div className="NW-contact-btn">
                <a
                  href={`tel:${salon?.mobile_number}`}
                  onClick={() => log_adder(salon?.name, "call_now")}
                >
                  Call Now
                </a>
                <button
                  onClick={() => {
                    handleBookNowBtn();
                  }}
                >
                  Book Now
                </button>
              </div>
              <div className="NW-offer-tag-salon">
                <span></span>
                <span>{salon?.offer_tag}</span>
                <span></span>
              </div>
              <div className="NW-facilities-desktop">
                {salon?.facilities &&
                  salon.facilities.map((data, index) => {
                    var iconNumber;
                    if (data === "Washroom") {
                      iconNumber = 6;
                    } else if (data === "Parking") {
                      iconNumber = 7;
                    } else if (data === "Sanitization") {
                      iconNumber = 8;
                    } else if (data === "Air conditioning") {
                      iconNumber = 9;
                    } else if (data === "Music") {
                      iconNumber = 10;
                    } else {
                      iconNumber = null;
                    }

                    return (
                      iconNumber && (
                        <div className="NW-facilities-icon" key={index}>
                          <img
                            src={require(`./../../../Assets/images/icons/${iconNumber}.png`)}
                            alt=""
                          />
                        </div>
                      )
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="salon-profile-hero-section">
          <div className="salon-profile-main-images">
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              navigation
              autoplay={{ delay: 6000, disableOnInteraction: false }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              <SwiperSlide>
                <img src={salon?.main_image} alt="" />
              </SwiperSlide>

              {salon?.mul_images &&
                salon?.mul_images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img src={image?.image} alt="" />
                  </SwiperSlide>
                ))}
            </Swiper>
            <div
              className="salon-p-main-score-tag"
              onClick={handleUserScoreOpen}
            >
              <img src={Score_svg} alt="" />
              <span>Score {calculateScore(scoreData) || 0}/10</span>
            </div>
            <div
              className="salon-p-main-info-icon"
              onClick={() => {
                handleAboutUsOpen();
              }}
            >
              <img src={Info_svg} alt="" />
            </div>
            <div className="salon-p-more-photo-btn">
              <button onClick={handleSalonProfilePhotosOpen}>
                <img src={Grids} alt="" />
                More Photos
              </button>
            </div>
          </div>
          <div className="salon-p-main-content">
            <div className="salon-p-main-content-inner">
              <div className="s-p-hero-opening">
                <span
                  onClick={() => {
                    setOpenSalonTiming(true);
                  }}
                >
                  {timings}
                </span>
                <div className="s-p-h-share-like-btn">
                  <button onClick={handleShare}>
                    <img src={share_svg} alt="" />
                  </button>
                  {userFavorites?.some((item) => {
                    let res = item?.salon === salon?.id;
                    return res;
                  }) ? (
                    <button
                      onClick={() => {
                        user ? handledislike(salon?.id) : handleSignInOpen();
                      }}
                    >
                      <FcLike />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        user ? handlelike(salon?.id) : handleSignInOpen();
                      }}
                    >
                      <MdFavoriteBorder />
                    </button>
                  )}
                </div>
              </div>
              <h1 className="salon-p-hero-name">{salon?.name}</h1>
              <div className="salon-p-hero-addr">
                <div>
                  {salon?.area} , {salon?.city}
                </div>
              </div>
              <div className="salon-p-hero-price-tag">
                <div>
                  <span>₹ {salon?.price} Onwards</span>
                </div>
              </div>
              <div className="salon-p-hero-call-book-btn">
                <a
                  href={`tel:${salon?.mobile_number}`}
                  className="salon-p-hero-call-btn"
                  onClick={() => {
                    log_adder(salon?.name, "call_now");
                  }}
                >
                  Call now
                </a>
                <button
                  className="salon-p-hero-book-btn"
                  onClick={handleBookNowBtn}
                >
                  Book now
                </button>
              </div>
              <div className="salon-p-hero-special-tag">
                {salon?.premium && <span>Premium</span>}
                {salon?.bridal && <span>Bridal</span>}
                {salon?.makeup && <span>Make up</span>}
              </div>
              <div className="salon-p-hero-offer-tag">
                <span></span>
                <span>{salon?.offer_tag}</span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      )}

      {salon?.client_images && salon?.client_images.length > 0 && (
        <>
          <div className="N-Profile-page-main-heading">
            <span>Customer's experience </span>
          </div>
          <ClientWorkPhoto
            workData={salon?.client_images}
            salonData={salon}
            handleClientExpOpen={handleClientExpOpen}
            openClientExp={clientExpOpen}
            handleClientExpClose={handleClientExpClose}
          />
        </>
      )}

      {salonOfferData?.length > 0 && (
        <>
          <div className="N-Profile-page-main-heading">
            <span>Offers</span>
          </div>
          <OfferSalon
            salon={salon}
            offerData={salonOfferData}
            handleOfferOpen={(data) => handleOfferOpen(data)}
            handleModalClose={handleModalClose}
            offerrModalOpen={offerrModalOpen}
            offerModalData={offerModalData}
          />
        </>
      )}

      {/* <MemberShip salon={salon} /> */}

      {allServices && (
        <>
          <div className="N-Profile-page-main-heading">
            <span>Salon Services </span>
          </div>
          <div className="N-salon-service-main-header">
            <div className="N-salon-service-gender">
              <div className="N-gender-btn-container">
                <button
                  className={`N-gender-male ${
                    activeServiceGender == "male" ? "active" : ""
                  }`}
                  onClick={() => setActiveServiceGender("male")}
                >
                  MALE
                </button>
                <button
                  className={`N-gender-female ${
                    activeServiceGender == "female" ? "active" : ""
                  }
            `}
                  onClick={() => setActiveServiceGender("female")}
                >
                  FEMALE
                </button>
              </div>
            </div>
            {categoryListOfGender?.length > 1 && (
              <div className="N-salon-service-category">
                <div className="N-category-list-salon-p">
                  {categoryListOfGender?.map((item, index) => {
                    return (
                      <span
                        key={item?.id}
                        style={{
                          background:
                            activeCategory === item?.id
                              ? "linear-gradient(to right, #AE86D0 , #512DC8)"
                              : "",
                          border: activeCategory === item?.id && "none",
                          color: activeCategory === item?.id ? "white" : "",
                        }}
                        onClick={() => {
                          setActiveCategory(item?.id);
                        }}
                      >{`${item.name}`}</span>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {window.innerWidth > 768 ? (
            <div className="N-profile-p-ser-score">
              <div className="ND-p-p-service-container">
                <Services
                  serviceData={activeServiceData}
                  mobile_number={salon?.mobile_number}
                  salonname={salon?.name}
                  salon={salon}
                />
                <GrommingPackages salon={salon} />
              </div>
              <div className="ND-score-container-outer">
                <div className="ND-score-container">
                  <div className="ND-score-count-total">
                    <span>{calculateScore(scoreData)}/10 Score </span>{" "}
                    <span>{scoreData?.length || "0"} user Scored</span>
                  </div>
                  <div className="ND-score-give-sec">
                    <div className="ND-user-score-giving">
                      <Swiper
                        slidesPerView={5}
                        spaceBetween={30}
                        centeredSlides={true}
                        className="Score-swiper-container"
                        ref={swiperRef}
                        onSlideChange={setMiddleScore}
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                          <SwiperSlide key={index}>
                            <div
                              className={`score-swiper-c-item 
                    ${activeScore === item ? "active" : ""}
                    `}
                              onClick={() => setActiveScore(item)}
                              score-value={item}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {item}
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    <button
                      className="ND-score-sub-btn"
                      onClick={handleUserScore}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="ND-user-score-list">
                    {scoreData
                      .slice(0, desktopVisibleScore)
                      ?.map((item, index) => (
                        <div className="ND-user-score-list-item">
                          <div className="ND-user-avtar-img">
                            <Avatar style={{ backgroundColor: "#532FCA" }}>
                              {item?.name?.[0] || "A"}
                            </Avatar>
                          </div>
                          <div className="ND-user-score-name">
                            <div className="ND-user-name-s">
                              {item?.name || "Anonymous"}
                            </div>
                            <div className="ND-user-score-s">
                              <img src={Score_svg} alt="" />
                              <span>{item?.score}/10 Score</span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {scoreData.length > desktopVisibleScore && (
                    <div
                      className=" w-fit px-4 py-[2px] mx-auto mt-3 border border-gray-700 rounded-2xl shadow-sm cursor-pointer "
                      onClick={() => {
                        setDesktopVisibleScore(desktopVisibleScore + 5);
                      }}
                    >
                      See more
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <Services
                serviceData={activeServiceData}
                mobile_number={salon?.mobile_number}
                salonname={salon?.name}
                salon={salon}
              />
              <GrommingPackages salon={salon} />
            </>
          )}
        </>
      )}

      {productData?.length > 0 && (
        <div className=" bg-gradient-to-r from-[#512DC8] to-[#512dc842] my-5 py-1 md:my-7 ">
          <div className=" font-medium text-base pl-4 py-3 text-white md:pl-10 md:py-5 md:text-xl">
            <h3>Products used by salon</h3>
          </div>
          <div className=" flex overflow-x-auto w-full snap-mandatory snap-x gap-4 pb-4 md:gap-6 md:pb-6 ">
            {productData?.map((item, index) => (
              <div className=" h-20 w-20 rounded-full object-cover snap-center first:ml-4 last:mr-4 shrink-0 md:first:ml-10 md:last:mr-10 md:h-24 md:w-24">
                <img src={item?.product_image} alt="" className=" rounded-full w-full h-full" />
              </div>
            ))}
          </div>
        </div>
      )}

      {salon && (
        <SalonDailyUpdates
          salonData={salon}
          open={dailyUpdatesOpen}
          handleOpen={handleDailyUpdatesOpen}
          handleClose={handleDailyUpdatesClose}
        />
      )}

      {suggestedSalonData?.length > 0 && (
        <>
          <div
            className="N-Profile-page-main-heading"
            style={{
              borderBottom: "none",
            }}
          >
            <span>Suggested Salons</span>
          </div>
          <div className="N-Profile-page-suggested-salons">
            {suggestedSalonData?.map((item, index) => {
              // Get the offer format and content using the function
              const { offerFormat, offerContent } = getOfferFormat(
                item?.custom_offer_tag,
                item?.offer_tag
              );

              return (
                <Link
                  to={`/${encodeURIComponent(item?.city)}/${encodeURIComponent(
                    item?.area
                  )}/salons/${encodeURIComponent(item?.slug)}`}
                  className="N-Profile-page-saggested-salon-card"
                  key={index}
                >
                  <div className="N-Profile-page-suggested-salon-card-img">
                    <img src={item?.main_image} alt="" />
                    <div className="like-salon-p-s-s">
                      {userFavorites?.some((item1) => {
                        let res = item1?.salon === item?.id;
                        return res;
                      }) ? (
                        <div
                          className="like-salon-p-s-s-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            handledislike(item?.id);
                          }}
                        >
                          <FcLike />
                        </div>
                      ) : (
                        <div
                          className="like-salon-p-s-s-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            handlelike(item?.id);
                          }}
                        >
                          <MdFavoriteBorder />
                        </div>
                      )}
                    </div>
                    <div className="offer-tag-p-s-s">
                      <span>
                        {offerFormat === "fixed-amount" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span className="custom-offer-tag-text1">
                              {offerContent.split(" ")[0].toUpperCase()}
                            </span>
                            <span className="custom-offer-tag-text2">
                              {" "}
                              {offerContent
                                .split(" ")
                                .slice(1)
                                .join(" ")
                                .toUpperCase()}
                            </span>
                          </div>
                        ) : offerFormat === "percentage" ||
                          offerFormat === "fixed-amount-service" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span className="custom-offer-tag-text1">
                              {offerContent.split(" ")[0].toUpperCase()}
                            </span>
                            <span className="custom-offer-tag-text2">
                              {" "}
                              {offerContent.split(" ")[1].toUpperCase()} OFF
                            </span>
                            <span className="custom-offer-tag-text3 line-clamp-1">
                              {" "}
                              ON{" "}
                              {offerContent
                                .split(" ")
                                .slice(4)
                                .join(" ")
                                .toUpperCase()}
                            </span>
                          </div>
                        ) : offerFormat === "service-price" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span className="custom-offer-tag-text1 line-clamp-1">
                              {offerContent
                                .split(" ")
                                .slice(0, -2)
                                .join(" ")
                                .toUpperCase()}
                            </span>{" "}
                            <span className="custom-offer-tag-text2">
                              {offerContent
                                .split(" ")
                                .slice(-2)[0]
                                .toUpperCase()}{" "}
                              {offerContent
                                .split(" ")
                                .slice(-1)[0]
                                .toUpperCase()}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                    {item?.premium && (
                      <div className="premium_div-x absolute">
                        <div className="premium-text">Premium</div>
                      </div>
                    )}
                  </div>
                  <div className="N-Profile-page-suggested-salon-card-content">
                    <h2>{item?.name}</h2>
                    <p className="N-P-S-S-score">
                      <img src={Score_svg} alt="" />
                      <span>
                        {item?.avg_score
                          ? String(item?.avg_score).slice(0, 3)
                          : 0}
                      </span>
                    </p>
                    <p className="N-P-S-S-addr">
                      {item?.area}, {item?.city}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}

      {window.innerWidth > 768 && (
        <>
          <div
            className="N-Profile-page-main-heading"
            style={{
              borderBottom: "none",
              alignItems: "end",
            }}
          >
            <span>About Us </span>
          </div>
          <AboutUsSalon about={salon?.about_us} />
        </>
      )}

      <FooterN city={params?.city || salon?.city || "ahmedabad"} />

      {/* <WhatsAppButton /> */}

      <Modal
        open={openSignIn}
        onClose={handleSignInClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isMobile ? (
          <Box
            sx={{
              ...style,
              bottom: 0,
              top: "auto",
              left: 0,
              right: 0,
              width: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
              transform: "none",
            }}
          >
            <Signup fun={handleSignInClose} />
          </Box>
        ) : (
          <Box sx={style}>
            <Signup fun={handleSignInClose} />
          </Box>
        )}
      </Modal>

      <Modal
        open={salonProfilePhotosTrigger}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Gallery2 salon={salon} onClose={handleSalonProfilePhotosClose} />
      </Modal>

      <Modal
        open={openAboutUs}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AboutUsModal salon={salon} handleAboutUsClose={handleAboutUsClose} />
      </Modal>
      <Modal
        open={openSalonTiming}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SalonTimingModal
          salon={salon}
          handleSalonTimingClose={() => {
            setOpenSalonTiming(false);
          }}
        />
      </Modal>
      <Modal
        open={userScoreModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UserScoreModal
          handleClose={() => handleUserScoreClose()}
          scoreData={scoreData}
          getScoreData={() => {
            if (salon?.id) {
              getScoreBySalon(salon?.id);
            }
          }}
          openSignIn={() => {
            setOpenSignIn(true);
          }}
          salonData={salon}
          openUserNameChangeModal={() => setUserNameChangeModal(true)}
          activeScoreS={setActiveScore}
        />
      </Modal>
      <Modal
        open={userNameChangeModal}
        // onClose={() => setUserNameChangeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" bg-white w-4/5 max-w-[400px] h-auto rounded-xl absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-5">
          <div className="flex justify-between flex-col gap-3 relative">
            <div className=" italic font-medium text-sm w-full text-red-600 flex gap-2 items-center">
              <InfoSharp
                sx={{
                  height: "20px",
                  width: "20px",
                }}
              />
              Your Name is mandatory to submit score
            </div>
            <label className=" text-lg font-semibold py-2" htmlFor="user-name">
              Enter Your Name
            </label>
            <button
              onClick={() => {
                setUserNameChangeModal(false);
              }}
              className="absolute -top-16 bg-white rounded-full right-1 p-1"
            >
              <Close />
            </button>
          </div>
          <div className="flex justify-between items-center flex-col gap-3">
            <input
              type="text"
              value={userNameOfUser}
              onChange={(e) => {
                setUserNameOfUser(e.target.value);
              }}
              id="user-name"
              className="w-full p-2 border border-gray-300 rounded-lg outline-none focus:border-[#532FCA]"
              placeholder="Your Name here"
            />
            <button
              onClick={(e) => {
                handleSubmitUserName(e);
              }}
              className={`w-full bg-[#532FCA] text-white p-2 rounded-lg ${
                nameUpdateLoading && "opacity-70"
              }`}
              disabled={nameUpdateLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SalonProfile;
