
const getSalonsNearYou = async (latitude = 0, longitude = 0, page) => {

  let url = `https://trakky.in:8000/salons/nearby-salon/?latitude=${Number(
    latitude
  )}&longitude=${Number(longitude)}&page=${page}&verified=true`;

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(url, requestOption);
    const data = await response.json();
    return data;
  }
  catch (err) {
    console.log(err);
  }

};

const getTopRatedSalons = async (city, area, page) => {

  let url = "https://trakky.in:8000/salons/filter/";
  if (city && area) {
    url += `?city=${city}&area=${area}`;
  } else if (city) {

    url += `?city=${city}`;

  } else {
    url = "https://trakky.in:8000/salons/?verified=true";
  }

  url += `&page=${page}&top_rated=true&verified=true`;

  let otherUrl = "https://trakky.in:8000/salons/salon-top-rated/data/";

  if (city && area) {

    otherUrl += `?city=${city}&area=${area}`;

  } else if (city) {

    otherUrl += `?city=${city}`;

  }

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(otherUrl, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};


const getBridalSalons = async (city, area, page) => {

  let url = "https://trakky.in:8000/salons/filter/";
  if (city && area) {

    url += `?city=${city}&area=${area}`;

  } else if (city) {

    url += `?city=${city}`;

  } else {
    url = "https://trakky.in:8000/salons/?verified=true";
  }

  url += `&page=${page}&bridal=true&verified=true`;

  let otherUrl = "https://trakky.in:8000/salons/salon-bridal/data/";

  if (city && area) {

    otherUrl += `?city=${city}&area=${area}`;

  } else if (city) {

    otherUrl += `?city=${city}`;

  }

  otherUrl += `&page=${page}`;



  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response2 = await fetch(otherUrl, requestOption);
    // const response = await fetch(url, requestOption);
    // const data = await response.json();
    const data2 = await response2.json();
    // console.log(
    //   "Bridal Salons",
    //   data2,
    // );
    return data2;
  } catch (err) {
    console.log(err);
  }
};

const getUnisexSalons = async (city, area, page) => {
  let url = "https://trakky.in:8000/salons/filter/";
  if (city && area) {

    url += `?city=${city}&area=${area}`;

  } else if (city) {

    url += `?city=${city}`;

  } else {
    url = "https://trakky.in:8000/salons/?verified=true";
  }

  url += `&page=${page}&unisex_salon=true&verified=true`;

  let otherUrl = "https://trakky.in:8000/salons/salon-unisex/data/";

  if (city && area) {

    otherUrl += `?city=${city}&area=${area}`;

  } else if (city) {

    otherUrl += `?city=${city}`;

  }

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };


  try {
    const response = await fetch(otherUrl, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }

};

const getKidsSalons = async (city, area, page) => {
  let url = "https://trakky.in:8000/salons/filter/";
  if (city && area) {

    url += `?city=${city}&area=${area}`;

  } else if (city) {

    url += `?city=${city}`;

  } else {
    url = "https://trakky.in:8000/salons/?verified=true";
  }

  url += `&page=${page}&kids_special_salons=true&verified=true`;


  let otherUrl = "https://trakky.in:8000/salons/salon-kids-special/data/";

  if (city && area) {

    otherUrl += `?city=${city}&area=${area}`;

  } else if (city) {

    otherUrl += `?city=${city}`;

  }

  otherUrl += `&page=${page}`;


  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(otherUrl, requestOption);
    const data = await response.json();
    return data;
  }
  catch (err) {
    console.log(err);
  }

};

const getFemaleBeautySalons = async (city, area, page) => {

  let url = "https://trakky.in:8000/salons/filter/";
  if (city && area) {

    url += `?city=${city}&area=${area}`;

  } else if (city) {

    url += `?city=${city}`;

  } else {

    url = "https://trakky.in:8000/salons/?verified=true";

  }

  let otherUrl = "https://trakky.in:8000/salons/salon-female-beauty-parlour/data/";

  if (city && area) {

    otherUrl += `?city=${city}&area=${area}`;

  } else if (city) {

    otherUrl += `?city=${city}`;

  }

  url += `&page=${page}&female_beauty_parlour=true&verified=true`;

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };


  try {
    const response = await fetch(otherUrl, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

const getAcademySalons = async (city, area, page) => {

  let url = "https://trakky.in:8000/salons/filter/";
  if (city && area) {

    url += `?city=${city}&area=${area}`;

  } else if (city) {

    url += `?city=${city}`;

  } else {

    url = "https://trakky.in:8000/salons/?verified=true";
  }

  url += `&page=${page}&salon_academy=true&verified=true`;

  let otherUrl = "https://trakky.in:8000/salons/salon-academy/data/";

  if (city && area) {

    otherUrl += `?city=${city}&area=${area}`;

  } else if (city) {

    otherUrl += `?city=${city}`;

  }

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(otherUrl, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }

};

const getMakeupSalons = async (city, area, page) => {

  let url = "https://trakky.in:8000/salons/filter/";
  if (city && area) {

    url += `?city=${city}&area=${area}`;

  } else if (city) {
    url += `?city=${city}`;

  } else {
    url = "https://trakky.in:8000/salons/?verified=true";
  }

  url += `&page=${page}&makeup=true`;

  let otherUrl = "https://trakky.in:8000/salons/salon-makeup/data/";

  if (city && area) {

    otherUrl += `?city=${city}&area=${area}`;

  } else if (city) {

    otherUrl += `?city=${city}`;

  }

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(otherUrl, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }

};

const getAllAreaSalons = async (city, area, page) => {

  let url = "https://trakky.in:8000/salons/filter/";

  if (city && area) {

    url += `?city=${city}&area=${area}`;

  } else if (city) {

    url += `?city=${city}`;

  } else {
    url = "https://trakky.in:8000/salons/?verified=true";
  }

  url += `&page=${page}&verified=true`;

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(url, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

const getCategories = async (city) => {
  let url = "https://trakky.in:8000/salons/category/";

  if (city) {

    url += `?city=${city}`;
  }

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(url, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};


const getOffers = async (city) => {
  let offerurl = "https://trakky.in:8000/salons/offer/";

  if (city) {

    offerurl += `?city=${city}`;
  }

  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(offerurl, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

const getMaleSalons = async (city, area, page) => {
  let url = "https://trakky.in:8000/salons/filter/";
  if (city && area) {

    url += `?city=${city}&area=${area}`;
  } else if (city) {
    url += `?city=${city}`;
  } else {
    url = "https://trakky.in:8000/salons/?verified=true";
  }

  url += `&page=${page}&male_salons=true&verified=true`;

  let otherUrl = "https://trakky.in:8000/salons/salon-male/data/";

  if (city && area) {

    otherUrl += `?city=${city}&area=${area}`;

  } else if (city) {

    otherUrl += `?city=${city}`;
  } 


  const requestOption = {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(otherUrl, requestOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }

};

export { getSalonsNearYou, getTopRatedSalons, getBridalSalons, getUnisexSalons, getKidsSalons, getFemaleBeautySalons, getAcademySalons, getMakeupSalons, getAllAreaSalons, getCategories, getOffers, getMaleSalons };