import React from "react";
import { useState } from "react";
import "../ModalComponent/salonprofilemodal.css";
import "./offersalon.css";
import OfferSalonModal from "../ModalComponent/OfferSalonModal";
import Modal from "@mui/material/Modal";

const OfferSalon = ({ salon , offerData ,handleOfferOpen,handleModalClose,offerModalData,offerrModalOpen  }) => {



  return (
    <>
      <div className="N-Profile-page-offers">
        {offerData?.map((item, index) => (
          <div
            className="N-Profile-page-offer-card"
            key={index}
            onClick={() => {
              handleOfferOpen(item);
            }}
          >
            <div className="N-Profile-page-offer-card-img">
              <img src={item?.image} alt="" />
            </div>
          </div>
        ))}
      </div>
      <Modal open={offerrModalOpen}>
        <OfferSalonModal
          open={offerrModalOpen}
          data={offerModalData}
          handleClose={() => {
            handleModalClose();
          }}
          salon={salon}
        />
      </Modal>
    </>
  );
};

export default OfferSalon;
