import React from "react";
import { useState, useEffect } from "react";
import AuthContext from "../../../../context/Auth";
import "./service.css";

const Services = (props) => {
  const { user } = React.useContext(AuthContext);
  const [visibleServices, setVisibleServices] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    setVisibleServices(5);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      setVisibleServices(props?.serviceData?.length);
    } else {
      setVisibleServices(5);
    }
  }, [isExpanded]);

  const toggleScroll = () => {

    if (isExpanded) {
     window.scrollTo({
        top: document.getElementById("salon-services").offsetTop,
      });
    }


    setIsExpanded(!isExpanded);
  };

  const { location } = React.useContext(AuthContext);

  const handleBookNowBtn = (item) => {
    if (!props.salon?.name) {
      return;
    }

    if (!item?.discount) {
      let message = `I want to book the ${item?.service_name} available at ${
        props.salon?.name
      } in ${props.salon?.area}, ${props.salon?.city}.
      
As it is mentioned, it comes with price of ${
        item.price
      } rupees and takes ${formateTime(
        item?.service_time
      )} to complete the service.
      
Please book it for me.`;
      setMsg(message);
    } else {
      let message = `I want to book the ${item?.service_name} available at ${
        props.salon?.name
      } in ${props.salon?.area}, ${props.salon?.city}.
      
As it is mentioned, it comes with discounted price of ${
        item.price
      } rupees and takes ${formateTime(
        item?.service_time
      )} to complete the service.
      
Please book it for me.`;
      setMsg(message);
    }

    let link = `https://api.whatsapp.com/send?phone=916355167304&text=${encodeURIComponent(
      msg
    )}`;

    window.open(link, "_blank");
  };

  const log_adder = async (name) => {
    const requestBody = {
      category: "salon",
      name: name,
      location,
    };

    if (user != null) {
      requestBody.salon_user = user?.user_id || null;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        "https://trakky.in:8000/salons/log-entry/",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to log entry");
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error logging entry:", error.message);
    }
  };

  const formateTime = (time) => {
    let str = "";

    if (time?.days) {
      str += time.days + " Days, ";
    }
    if (time?.seating) {
      str += time.seating + " Seating, ";
    }
    if (time?.hours) {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes) {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  useEffect(() => {
    setIsExpanded(false);
  }, [props?.serviceData]);

  //AE86D0 512DC8

  // scroll spy

  //   const handleScroll = () => {
  //     // check service in viewport
  //     let serviceItems = document.querySelectorAll(".N-Main-Service-Item");
  //     let serviceItemsArray = Array.from(serviceItems);
  //     let viewportItem = [];

  //     serviceItemsArray.forEach((item) => {
  //       let rect = item.getBoundingClientRect();
  //       if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
  //         viewportItem.push(item.getAttribute("data-service-cateogry"));
  //       }
  //     });

  //     console.log("viewportItem", viewportItem[0]);

  //   };

  //   let lastScrollPosition = 0;
  // function debouncedHandleScroll() {
  //  const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  //  const scrollDifference = Math.abs(currentScrollPosition - lastScrollPosition);

  //  if (scrollDifference >= 10) {
  //     handleScroll();
  //     lastScrollPosition = currentScrollPosition;
  //  }
  // }

  //   useEffect(() => {

  //     window.addEventListener("scroll", debouncedHandleScroll);
  //     return () => {
  //       window.removeEventListener("scroll", debouncedHandleScroll);
  //     };

  //   }, []);

  return (
    <div className="N-service-main-outer-container" id="salon-services">
      {props?.serviceData?.length > 0 ? (
        <div className={`w-[100%]`}>
          <div className="N-Service-main-container">
            {props?.serviceData
              ?.slice(0, visibleServices)
              .map((item, index) => {
                return (
                  <div
                    className="N-Main-Service-Item"
                    data-service-cateogry={item?.categories}
                  >
                    <div className="N-Service-Image-Div">
                      <div className="N-Service-Image-container">
                        {item?.service_image && (
                          <img src={item.service_image} />
                        )}
                      </div>
                      <button
                        className="N-Service-call-now-btn-md"
                        onClick={() => {
                          handleBookNowBtn(item);
                        }}
                      >
                        {/* <a
                          href={`tel:${props.mobile_number}`}
                          onClick={() => log_adder(props.salonname)}
                        >
                          Book Now
                      </a> */}
                        Book Now
                      </button>
                    </div>
                    <div className="N-Service-Content-Div">
                      <div className="N-Service-Title-Div">
                        {item?.service_name}
                      </div>
                      <div className="N-Service-Description-Div">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      </div>
                      <div className="N-Service-price-book-Div">
                        <div className="N-Service-pricing-Div">
                          {item?.discount > 0 ? (
                            <>
                              <span className="N-s-p-d-actual-p">
                                ₹{item?.discount}
                              </span>
                              <span className=" pl-[6px] text-gray-500 flex">
                                ₹
                                <del>
                                  <span>{item?.price}</span>
                                </del>
                              </span>
                            </>
                          ) : (
                            <span className="N-s-p-d-actual-p">
                              ₹{item?.price}
                            </span>
                          )}
                          <span
                            style={{
                              paddingLeft: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            ●
                          </span>
                          <span
                            style={{
                              color: " #646464",
                            }}
                          >
                            {formateTime(item?.service_time)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {(props?.serviceData.length > visibleServices || isExpanded) && (
            <div className="N-view-more-button-container">
              <button onClick={toggleScroll}>
                {isExpanded ? "View Less" : "See all Services"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="N-service-no-data">
          <h2 className="text-[20px] font-medium h-[100px] w-full flex justify-center items-center">
            We will add menu soon!
          </h2>
        </div>
      )}
    </div>
  );
};

export default Services;
