import React from "react";
import trakkypurple from "../../../Assets/images/logos/Trakky logo purple.png";
import AuthContext from "../../../context/Auth";

const styles = {
  appearance: "none",
  WebkitAppearance: "none",
  MozAppearance: "none",
  backgroundImage: "none",
  border: "none",
};

function WhatsappForm() {
  const { user, authTokens , userData } = React.useContext(AuthContext);
  const [selectedGender, setSelectedGender] = React.useState("male");
  const [City, setCity] = React.useState([]);
  const [area, setarea] = React.useState([]);
  const [CategoriesData, setCategoriesData] = React.useState([]);
  const [formData, setFormData] = React.useState({
    city: null,
    area: null,
  });


  const [whatsappData, setWhatsappData] = React.useState({
    city: "",
    area: "",
    salon_service: "",
    other_service: "",
    other_city: "",
    other_area: "",
  });

  // Getting Category Data
  React.useEffect(() => {
    const getCategories = () => {
      const requestOption = {
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      };
      fetch(`https://trakky.in:8000/salons/mastercategory/?gender=${selectedGender}`, requestOption)
        .then((res) => res.json())
        .then((data) => {
          setCategoriesData(data);
        })
        .catch((err) => console.log(err));
    };
    getCategories();
  }, [selectedGender]);

  const getcity = async () => {
    const response = await fetch("https://trakky.in:8000/salons/city/");
    const data = await response.json();
    setCity(data.payload);
  };

  const getArea = () => {
    if (formData.city === null || formData.city === "Other") {
      return;
    }
    const data = City.filter((item) => {
      return item.id === parseInt(formData.city);
    });
    setarea(data[0].area_names);
  };

  React.useEffect(() => {
    getArea();
  }, [formData.city]);

  React.useEffect(() => {
    getcity();
  }, []);

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleServiceChange = (event) => {
    setWhatsappData({ ...whatsappData, salon_service: event.target.value });
  };

  const handleWhatsAppChat = () => {
    const { name, phone_number, email } = userData;
    const {
      salon_service,
      city,
      area,
      other_city,
      other_area,
      other_service,
    } = whatsappData;
  
    // Handle "Other" cases
    const updatedData = { ...whatsappData };
    if (city === "Other" && other_city) {
      updatedData.city = other_city;
      delete updatedData.other_city;
    }
  
    if (area === "Other" && other_area) {
      updatedData.area = other_area;
      delete updatedData.other_area;
    }
  
    if (salon_service === "Other" && other_service) {
      updatedData.salon_service = other_service;
      delete updatedData.other_service;
    }
  
    // Validate mandatory fields
    if (!salon_service || !city || !area) {
      alert("Please select service, city, and area");
      return;
    }
  
    const whatsappMessage = `Can I get information about the salon for ${
      updatedData.salon_service
    } in ${updatedData.area}, ${
      updatedData.city
    }?\n\nGender: ${selectedGender}\n\n`;
  
    let whatsappURL = `https://api.whatsapp.com/send?phone=916355167304&text=${encodeURIComponent(
      whatsappMessage
    )}`;
  
    // Append user data if available
    if (name && phone_number) {
      const userDataString = `Name: ${
        name
      }\nPhone Number: ${
        phone_number
      }\nEmail: ${email}`;
      whatsappURL += `%0A%0A${encodeURIComponent(userDataString)}`;
    }
  
    // Open the WhatsApp chat in a new tab/window
    window.open(whatsappURL, "_blank");
  };
  

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleWhatsAppChat();
      }}
    >
      <div className="shadow-lg bg-white flex max-w-[360px] flex-col px-7 sm:px-10 py-6 rounded-3xl">
        <div className="self-center flex items-stretch gap-2 mt-2.5">
          <img src={trakkypurple} style={{ height: "30px" }} alt="logo" />
          <div className="text-black text-base grow whitespace-nowrap mt-1.5 self-start">
            For Grooming
          </div>
        </div>
        <div
          className="justify-between items-center shadow-sm bg-slate-200 self-stretch flex gap-5 mt-5 rounded-xl"
          style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
        >
          <input
            type="radio"
            name="gender"
            id="male"
            className="hidden"
            value="male"
            onChange={handleGenderChange}
            checked={selectedGender === "male"}
            required
          />
          <label
            htmlFor="male"
            className={`${
              selectedGender === "male"
                ? "text-black text-sm whitespace-nowrap justify-center items-stretch self-stretch shadow-sm bg-white px-12 py-2 rounded-xl cursor-pointer with-shadow"
                : "text-neutral-600 text-sm my-auto cursor-pointer "
            }`}
            style={{ paddingLeft: "3rem" }}
          >
            Male
          </label>
          <input
            type="radio"
            name="gender"
            id="female"
            className="hidden"
            value="female"
            onChange={handleGenderChange}
            checked={selectedGender === "female"}
            required
          />
          <label
            htmlFor="female"
            className={`${
              selectedGender === "female"
                ? "text-black text-sm whitespace-nowrap justify-center items-stretch self-stretch shadow-sm bg-white px-12 py-2 rounded-xl cursor-pointer with-shadow"
                : "text-neutral-600 text-sm my-auto cursor-pointer"
            }`}
            style={{ paddingRight: "3rem" }}
          >
            Female
          </label>
        </div>
        <div className="text-black text-sm self-stretch mt-7 ml-1">
          Salon Service
        </div>
        <select
          type="text"
          className="text-slate-600 text-sm whitespace-nowrap bg-slate-200 self-stretch justify-center mt-2.5 pl-3 pr-16 py-2 rounded-xl items-start"
          name="salon_service"
          id=""
          style={styles}
          onChange={handleServiceChange}
          required
        >
          <option className="text-[0.9rem]" disabled selected hidden>
            Select Salon Service
          </option>
          {CategoriesData?.map((item) => {
            return (
              <option className="text-[0.9rem]" style={{ background: "white" }}>
                {item.name}
              </option>
            );
          })}
          <option className="text-[0.9rem]" style={{ background: "white" }}>
            Other
          </option>
        </select>
        {whatsappData.salon_service === "Other" && (
          <input
            type="text"
            className="text-slate-600 text-sm whitespace-nowrap bg-slate-200 self-stretch justify-center mt-2.5 pl-3 pr-16 py-2 rounded-xl items-start"
            name="salon_service"
            id=""
            placeholder="Enter Salon Service"
            value={whatsappData.other_service}
            onChange={(e) => {
              setWhatsappData({ ...whatsappData, other_service: e.target.value });
            }}
            required
          />
        )}
        <div className="text-black text-sm self-stretch mt-4 ml-1">City</div>
        <select
          className="text-slate-600 text-sm whitespace-nowrap bg-slate-200 self-stretch justify-center mt-2.5 pl-3 pr-16 py-2 rounded-xl items-start"
          name="city"
          id=""
          value={formData.city}
          onChange={(e) => {
            setFormData({ ...formData, city: e.target.value });
            if (e.target.value === "Other") {
              setWhatsappData({ ...whatsappData, city: "Other" })
              return;
            }
            const data = City.find((item) => {
              return item.id === parseInt(e.target.value);
            });
            setWhatsappData({ ...whatsappData, city: data.name });
          }}
          style={styles}
          required
        >
          <option className="text-[0.9rem]" disabled selected hidden>
            Select City
          </option>
          {City?.map((item) => {
            return (
              <option
                className="text-[0.9rem]"
                style={{ background: "white" }}
                value={item.id}
              >
                {item.name}
              </option>
            );
          })}
          <option className="text-[0.9rem]" style={{ background: "white" }}>
            Other
          </option>
        </select>
        {formData.city === "Other" && (
          <input
            type="text"
            className="text-slate-600 text-sm whitespace-nowrap bg-slate-200 self-stretch justify-center mt-2.5 pl-3 pr-16 py-2 rounded-xl items-start"
            name="city"
            id=""
            placeholder="Enter City"
            value={whatsappData.other_city}
            onChange={(e) => {
              setWhatsappData({ ...whatsappData, other_city: e.target.value });
            }}
            required
          />
        )}
        <div className="text-black text-sm self-stretch mt-4 ml-1">Area</div>
        {formData.city !== "Other" ? (
        <select
          type="text"
          className="text-slate-600 text-sm whitespace-nowrap bg-slate-200 self-stretch justify-center mt-2.5 pl-3 pr-16 py-2 rounded-xl items-start"
          name="area"
          id=""
          value={whatsappData.area}
          onChange={(e) => {
            setWhatsappData({ ...whatsappData, area: e.target.value });
          }}
          style={styles}
          required
        >
          <option className="text-[0.9rem]" selected>
            Select Area
          </option>
          {area?.map((item) => {
            return (
              <option
                className="text-[0.9rem]"
                value={item}
                style={{ background: "white" }}
              >
                {item}
              </option>
            );
          })}
        </select>
        ) : (
          <input
            type="text"
            className="text-slate-600 text-sm whitespace-nowrap bg-slate-200 self-stretch justify-center mt-2.5 pl-3 pr-16 py-2 rounded-xl items-start"
            name="area"
            id=""
            placeholder="Enter Area"
            value={whatsappData.area}
            onChange={(e) => {
              setWhatsappData({ ...whatsappData, area: e.target.value });
            }}
            required
          />
        )}
        <button
          type="submit"
          className="text-white text-sm whitespace-nowrap justify-center items-center bg-cyan-600 self-stretch mt-6 px-4 py-2 rounded-xl"
        >
          Suggest Salon & Offers On Whatsapp
        </button>
        <div className="text-black text-xs font-light self-center whitespace-nowrap mt-3.5">
          Get Amazing Discounts & Offers Now
        </div>
      </div>
    </form>
  );
}

export default WhatsappForm;
