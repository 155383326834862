import React from "react";
// import Nav from "./Nav";
import Navbar from '../parterPage/Navbar/Navbar';
import Hero from "./Hero";
import Title from "./Title";
import Footer from "../Common/Footer/FooterN";
function Contactus() {
  return (
    <>
      {/* <Nav /> */}
      <Navbar />
      <Title />
      <Hero />
      <Footer/>
    </>
  );
}

export default Contactus;
