import React from "react";
import "./salonprofilemodal.css";
import leftArrow from "../../../../Assets/images/icons/long_left.svg";
import share_svg from "../../../../Assets/images/icons/share_icon_n.svg";
import moment from "moment";

const CustomerExp = ({
  category,
  closeModal,
  salonData,
  workData,
  tempSelectedCategory,
  tempSelectedImage,
}) => {
  let categoryData = categorizeData(workData, category?.id);

  function categorizeData(data, activeCategoryId) {
    const categorizedData = {};

    data.forEach((item) => {
      const categoryName = item.category_data.name;
      const categoryGender = item.category_data.category_gender;
      const objId = item.id;
      const categoryId = item.category_data.id;
      const uniqueKey =
        categoryName + "_" + categoryGender + "_" + categoryId + "_" + objId;

      if (!categorizedData[uniqueKey]) {
        categorizedData[uniqueKey] = [];
      }
      categorizedData[uniqueKey].push(item);
    });

    let result = Object.entries(categorizedData).map(([uniqueKey, catData]) => {
      const [catName, catGender, catId, objId] = uniqueKey.split("_");
      return {
        cat_name: catName,
        cat_gender: catGender,
        cat_id: catId,
        cat_data: catData,
        id: objId,
      };
    });

    console.log("result", result);
    console.log("result dds", tempSelectedImage);

    result.sort((a, b) => {
      const isActiveA = a.id == tempSelectedImage;
      const isActiveB = b.id == tempSelectedImage;

      if (isActiveA === isActiveB) return 0;
      if (isActiveA) return -1;
      if (isActiveB) return 1;
    });

    // if (activeCategoryId === "all") return result;
    if (activeCategoryId === "all") {
      return result.sort((a, b) => {
        const isActiveA = parseInt(a.cat_id) === tempSelectedCategory;
        const isActiveB = parseInt(b.cat_id) === tempSelectedCategory;

        if (isActiveA === isActiveB) return 0;
        if (isActiveA) return -1;
        if (isActiveB) return 1;
      });
    }


    result.sort((a, b) => {
      const isActiveA = parseInt(a.cat_id) === activeCategoryId;
      const isActiveB = parseInt(b.cat_id) === activeCategoryId;

      if (isActiveA === isActiveB) return 0;
      if (isActiveA) return -1;
      if (isActiveB) return 1;
    });


    return result;
  }

  const handleShare = () => {
    if (!navigator.share) {
      document.execCommand("copy", true, window.location.href);
      alert("Link copied to clipboard");
      return;
    }

    navigator
      .share({
        title: salonData?.name || "Trakky",
        text: "Check out this salon",
        url: window.location.href,
      })
      .catch((error) => console.log("Error sharing", error));
  };

  const formatTime = (dateTime) => {
    const now = moment();
    const createdAt = moment(dateTime);
    const duration = moment.duration(now.diff(createdAt));
    const hours = duration.asHours();

    if (hours < 24) {
      return `${Math.floor(hours)} hours ago`;
    } else {
      return createdAt.format("MMM DD, YYYY");
    }
  };

  return (
    <div className="CE-main-container">
      <div className="CE-modal-header">
        <img
          src={leftArrow}
          alt=""
          onClick={() => {
            closeModal();
          }}
          className=" !cursor-pointer"
        />
        <h2>Customer's experience</h2>
      </div>
      {categoryData?.map((item, index) => {
        return (
          <div key={index}>
            <div className="CE-modal-category-title">
              <h3>
                {item?.cat_name} ( {item?.cat_gender} )
              </h3>
              <span></span>
            </div>
            <div className="CE-customer-exp-item-outer">
              {item?.cat_data?.map((item, index) => {
                return (
                  <div className="CE-customer-exp-item" key={index}>
                    <div className="CE-c-i-salon-info">
                      <img src={salonData?.main_image} alt="" />
                      <h4>{salonData?.name}</h4>
                    </div>
                    <div className="CE-customer-exp-item-img max-h-[400px] md:max-h-[500px] object-contain !bg-white">
                      <img
                        src={item?.client_image}
                        alt=""
                        className=" max-h-[400px] md:max-h-[500px] object-contain "
                      />
                    </div>
                    <div className="CE-customer-exp-item-details">
                      <div className="CE-c-e-name-share-img">
                        <div className="CE-item-share-like">
                          <button
                            onClick={() => {
                              handleShare();
                            }}
                          >
                            <img src={share_svg} alt="" />
                          </button>
                        </div>
                        <div className="CE-item-service-name">
                          {item?.service}
                        </div>
                      </div>
                      <div className="CE-item-item-desc">
                        {item?.description}
                      </div>
                      <div className="CE-item-item-time pt-1">
                        {formatTime(item?.created_at)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomerExp;
