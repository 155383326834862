import React, { useState, useEffect } from "react";
import "./../salonprofilepage.css";
import "./groomingpackages.css";
import Packages_svg from "./../../../../Assets/images/icons/Packages_svg.svg";
import PackagesModal from "../ModalComponent/PackagesModal";
import Modal from "@mui/material/Modal";

const GrommingPackages = ({ salon }) => {
  const [visiblePackages, setVisiblePackages] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [packagesData, setPackagesData] = useState([]);
  const [selectedPackageData, setSelectedPackageData] = useState(null);

  const [openPackagesModal, setOpenPackagesModal] = useState(false);
  const handlePackagesModalOpen = () => {
    setOpenPackagesModal(true);
  };
  const handlePackagesModalClose = () => {
    setOpenPackagesModal(false);
  };

  const getPackagesData = async (salonId) => {
    let url = `https://trakky.in:8000/salons/packages/?salon_id=${salonId}`;

    try {
      let response = await fetch(url);
      let data = await response.json();
      setPackagesData(data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (salon) {
      getPackagesData(salon?.id);
    }
    setVisiblePackages(3);
  }, [salon]);

  useEffect(() => {
    if (isExpanded) {
      setVisiblePackages(packagesData?.length);
    } else {
      setVisiblePackages(3);
    }
  }, [isExpanded]);

  const handleBookNowBtn = (packageData) => {
    if (!salon?.name) {
      return;
    }

    let serviceList = Object.keys(packageData?.service_included_names)
      .map((key, index) => {
        return packageData?.service_included_names[key];
      })
      .join(", ");

    let message = `Can you provide more details about the services Package named '${packageData?.package_name}' at ${salon?.name} in ${salon?.area}, ${salon?.city}? It comes with a discounted price of ${packageData?.discount_price} rupees and included ${packageData?.service_included?.length} service (${serviceList}).`;

    let link = `https://api.whatsapp.com/send?phone=916355167304&text=${encodeURIComponent(
      message
    )}`;

    window.open(link, "_blank");
  };

  const togglePackagesVisibility = () => {
    setIsExpanded(!isExpanded);
  };

  const formateTime = (time) => {
    let str = "";

    if (time?.days) {
      str += time.days + " Days, ";
    }
    if (time?.seating) {
      str += time.seating + " Seating, ";
    }
    if (time?.hours) {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes) {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  return (
    <>
      {packagesData?.length > 0 && (
        <>
          <div className="N-Profile-page-main-heading">
            <span>Grooming Packages </span>
          </div>
          <div className="GP-list-container">
            {packagesData?.slice(0, visiblePackages).map((item, index) => {
              return (
                <div className="GP-list-item">
                  <div className="GP-package-image">
                    <img src={Packages_svg} alt="" /> PACKAGE
                  </div>
                  <div className="GP-package-name-book">
                    <h2>{item?.package_name}</h2>
                    <button
                      onClick={() => {
                        handleBookNowBtn(item);
                      }}
                    >
                      Book Now
                    </button>
                  </div>
                  <div className="GP-package-price-time">
                    <span>₹{parseInt(item?.discount_price)}</span>
                
                    <span className=" pl-[6px] text-gray-500 text-sm flex">
                                ₹
                                <del>
                                <span>{parseInt(item?.actual_price)}</span>
                                </del>
                              </span>
                    <span>●</span>
                    <span>{formateTime(item?.package_time)}</span>
                  </div>
                  <div className="GP-package-service-inc">
                    <ul>
                      {Object.keys(item?.service_included_names).map(
                        (key, index) => {
                          return <li>{item?.service_included_names[key]}</li>;
                        }
                      )}
                    </ul>
                  </div>
                  <div
                    className="GP-package-show-details"
                    onClick={() => {
                      setSelectedPackageData(item);
                      handlePackagesModalOpen();
                    }}
                  >
                    Show Details
                  </div>
                </div>
              );
            })}
          </div>
          {(packagesData?.length > visiblePackages || isExpanded) && (
            <div
              className="GP-show-all-p-btn"
              onClick={() => togglePackagesVisibility()}
            >
              {isExpanded ? "See less" : "See all packages"}
            </div>
          )}
        </>
      )}
      <Modal open={openPackagesModal}>
        <PackagesModal
          data={selectedPackageData}
          handleClose={() => {
            handlePackagesModalClose();
          }}
        />
      </Modal>
    </>
  );
};

export default GrommingPackages;
