import React, { useEffect, useState } from "react";
import "./clientworkimages.css";
import leftArrow from "../../../../Assets/images/icons/oui_arrow-left.svg";
import Modal from "@mui/material/Modal";
import CustomerExp from "../ModalComponent/CustomerExp";

const ClientWorkPhoto = (props) => {
  // devide data according to category

  const [clientWorkData, setClientWorkData] = useState(props?.workData);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [activeCategory, setActiveCategory] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const [tempSelectedCategory, setTempSelectedCategory] = useState("all");
  const [tempSelectedImage, setTempSelectedImage] = useState("");

  useEffect(() => {
    let uniqueCategoryArray = [];

    clientWorkData?.forEach((work) => {
      const CatId = work?.category;
      const CatName = work?.category_data?.category_name;

      const CategoryPair = { id: CatId, name: CatName };

      if (!uniqueCategoryArray.some((category) => category.id === CatId)) {
        uniqueCategoryArray.push(CategoryPair);
      }
    });

    setCategoryOptions([
      {
        id: "all",
        name: "All",
      },
      ...uniqueCategoryArray,
    ]);
  }, [clientWorkData]);

  useEffect(() => {
    if (activeCategory === "all") {
      setFilteredData(clientWorkData);
    } else {
      let filteredData = clientWorkData.filter((work) => {
        return work?.category === activeCategory;
      });
      setFilteredData(filteredData);
    }
  }, [activeCategory]);

  return (
    <>
      <div className="N-sp-room-main-container">
        <div className="N-sp-room-category-container">
          {categoryOptions?.length > 1 ? (
            categoryOptions.map((item, index) => {
              return (
                <span
                  key={item?.id}
                  onClick={() => {
                    setActiveCategory(item?.id);
                  }}
                  className={activeCategory == item?.id && "active"}
                >
                  {item.name}
                </span>
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className="N-sp-room-item-container">
          {filteredData?.length ? (
            filteredData.map((work, index) => {
              return (
                <div
                  className="N-sp-room-item-div"
                  onClick={()=> {
                    setTempSelectedCategory(work?.category);
                    setTempSelectedImage(work?.id);
                    props?.handleClientExpOpen();
                  }}
                >
                  <div className="N-sp-room-img">
                    {" "}
                    {work?.client_image && (
                      <img src={work?.client_image} alt="service-img" />
                    )}
                  </div>
                  <div className="N-sp-room-item-details">
                    {work?.service || "Service Name"}
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
        <div
          className="N-sp-room-item-see-more cursor-pointer !w-fit ml-auto"
          onClick={props?.handleClientExpOpen}
        >
          see more <img src={leftArrow} alt="" />
        </div>
        <div className="N-sp-room-divider"></div>
      </div>
      <Modal
        open={props?.openClientExp}
        aria-labelledby="customer-experience-modal"
        aria-describedby="customer-experience-modal"
        sx={{
          zIndex: 11,
        }}
        onClose={props?.handleClientExpClose}
      >
        <CustomerExp
          closeModal={props?.handleClientExpClose}
          salonData={props?.salonData}
          tempSelectedCategory={tempSelectedCategory}
          tempSelectedImage={tempSelectedImage}
          workData={clientWorkData}
          category={
            categoryOptions.find((item) => item.id === activeCategory) || {}
          }
        />
      </Modal>
    </>
  );
};

export default ClientWorkPhoto;
